import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  sectionMargins,
  sectionPaddings,
} from '../utilities';
import { Container, Heading, CardsLink } from './ui';
import PostCard from './PostCard';

const StyledPostCards = styled.section`
  ${({ margins }) => {
    if (margins) {
      return sectionMargins();
    }
  }}

  ${({ paddings }) => {
    if (paddings) {
      return sectionPaddings();
    }
  }}

  ${({ backgroundColour }) => {
    if (backgroundColour) {
      return `
        background-color: ${backgroundColour}
      `;
    }
  }}
`;

const StyledPostCardsHeading = styled(Heading)`
  margin-bottom: 20px;
`;

const StyledPostCardsInner = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(3, calc((100% - 40px) / 3));
    grid-column-gap: 20px;
  `}
`;

const PostCards = ({
  heading,
  items,
  baseSlug,
  link,
  flipCards,
  margins,
  paddings,
  backgroundColour,
}) => {
  return (
    <StyledPostCards
      margins={margins}
      paddings={paddings}
      backgroundColour={backgroundColour}
    >
      <Container>
        {heading && (
          <StyledPostCardsHeading colour={heading.colour} align={heading.align}>
            {heading.text}
          </StyledPostCardsHeading>
        )}
        <StyledPostCardsInner>
          {items.map(item => (
            <PostCard
              key={item.id}
              data={item}
              baseSlug={baseSlug}
              flipCard={flipCards}
            />
          ))}
        </StyledPostCardsInner>
        {link && (
          <CardsLink slug={link.slug} alt={link.alt}>
            {link.text}
          </CardsLink>
        )}
      </Container>
    </StyledPostCards>
  );
};

export default PostCards;
