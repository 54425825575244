import React from 'react';
import { navigate, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { minBreakpointQuery, brandColours, fontSize } from '../utilities';

const StyledPostCard = styled.article`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  background-color: ${brandColours.quaternary};
  cursor: pointer;

  ${({ flipCard }) => {
    if (flipCard) {
      return `
        flex-direction: column-reverse;
      `;
    }
  }}

  ${minBreakpointQuery.tsmall`
    flex-direction: row;

    ${({ flipCard }) => {
      if (flipCard) {
        return `
          flex-direction: row-reverse;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.medium`
    flex-direction: column;

    ${({ flipCard }) => {
      if (flipCard) {
        return `
          flex-direction: column-reverse;
        `;
      }
    }}
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
    margin-bottom: 30px;
  `}
`;

const StyledPostCardBlock = styled.div`
  ${minBreakpointQuery.tsmall`
    width: 50%;
  `}

  ${minBreakpointQuery.medium`
    width: auto;
  `}
`;

const StyledPostCardImage = styled(Img)`
  min-height: 100%;
`;

const StyledPostCardContent = styled.div`
  padding: 30px 20px;
  min-height: 120px;

  ${minBreakpointQuery.tsmall`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  `}

  ${minBreakpointQuery.small`
    padding: 40px 30px;
  `}

  ${minBreakpointQuery.medium`
    display: block;
    padding: 30px 20px;
    height: auto;
  `}

  ${minBreakpointQuery.large`
    padding: 40px 30px;
  `}
`;

const StyledPostCardHeading = styled.h3`
  ${fontSize(18)};

  ${minBreakpointQuery.tiny`
    ${fontSize(20)};
  `}
`;

const StyledPostCardDate = styled.p`
  margin-top: 10px;

  ${minBreakpointQuery.tiny`
    margin-top: 12px;
  `}
`;

const PostCard = ({
  data: {
    title,
    slug,
    featuredImage,
    dateText = false,
    dateAttribute = false,
    meta = false,
  },
  baseSlug,
  flipCard,
}) => {
  return (
    <StyledPostCard
      flipCard={flipCard}
      onClick={() => navigate(baseSlug + slug)}
    >
      <StyledPostCardBlock>
        <StyledPostCardImage
          fluid={featuredImage.fluid}
          alt={featuredImage.alt}
        />
      </StyledPostCardBlock>
      <StyledPostCardBlock>
        <StyledPostCardContent>
          <StyledPostCardHeading>
            <Link to={baseSlug + slug}>{title}</Link>
          </StyledPostCardHeading>
          <StyledPostCardDate>
            <time dateTime={dateAttribute || meta.firstPublishedAtAttribute}>
              {dateText || meta.firstPublishedAtText}
            </time>
          </StyledPostCardDate>
        </StyledPostCardContent>
      </StyledPostCardBlock>
    </StyledPostCard>
  );
};

export default PostCard;
