import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import FeaturedPost from '../components/FeaturedPost';
import PostCards from '../components/PostCards';
import ModularBlocks from '../components/ModularBlocks';

const EventsArchiveTemplate = ({
  data: {
    datoCmsEventsArchive: {
      seoMetaTags,
      title,
      bannerImage,
      featuredEvent,
      modularBlocks,
    },
    allDatoCmsEventSingle: { nodes },
  },
}) => {
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <main>
        <Banner title={title} image={bannerImage} />
        {featuredEvent && (
          <FeaturedPost
            data={featuredEvent}
            postType="Event"
            baseSlug="/events/"
          />
        )}
        <PostCards
          heading={{
            text: 'All Upcoming Events',
          }}
          items={nodes}
          baseSlug="/events/"
          margins={true}
        />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export const EventsArchiveTemplateQuery = graphql`
  query EventsArchiveTemplateQuery(
    $todaysDate: Date!
    $featuredEventId: String!
  ) {
    datoCmsEventsArchive {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        fluid(
          maxHeight: 380
          maxWidth: 1600
          imgixParams: { auto: "compress", fit: "crop", h: "380", w: "1600" }
        ) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      featuredEvent {
        id
        title
        slug
        featuredImage {
          fixed(
            height: 470
            width: 600
            imgixParams: { auto: "compress", fit: "crop", h: "470", w: "600" }
          ) {
            ...GatsbyDatoCmsFixed
          }
          alt
        }
        dateText: date(formatString: "DD MMMM YYYY")
        dateAttribute: date(formatString: "YYYY-MM-DD")
      }
      modularBlocks {
        ... on DatoCmsContentModularBlock {
          id
          model {
            apiKey
          }
          content
        }
        ... on DatoCmsImageContentModularBlock {
          id
          model {
            apiKey
          }
          heading
          content
          image {
            fluid(
              maxHeight: 680
              maxWidth: 600
              imgixParams: { auto: "compress", fit: "crop", h: "680", w: "600" }
            ) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
          flip
        }
      }
    }
    allDatoCmsEventSingle(
      filter: { date: { gte: $todaysDate }, id: { ne: $featuredEventId } }
      sort: { fields: date, order: ASC }
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          fluid(
            maxHeight: 280
            maxWidth: 480
            imgixParams: { auto: "compress", fit: "crop", h: "280", w: "480" }
          ) {
            ...GatsbyDatoCmsFluid
          }
          alt
        }
        dateText: date(formatString: "DD MMMM YYYY")
        dateAttribute: date(formatString: "YYYY-MM-DD")
      }
    }
  }
`;

export default EventsArchiveTemplate;
