import React from 'react';
import { navigate, Link } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  sectionMargins,
} from '../utilities';
import { Container, ResRetImg } from './ui';

const StyledFeaturedPost = styled.article`
  ${sectionMargins()};
`;

const StyledFeaturedPostInner = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${brandColours.primary};
  cursor: pointer;

  ${minBreakpointQuery.small`
    flex-direction: row;
  `}
`;

const StyledFeaturedPostBlock = styled.div`
  ${minBreakpointQuery.small`
    width: 50%;
  `}

  ${minBreakpointQuery.mlarge`
    width: auto;
  `}
`;

const StyledFeaturedPostImage = styled(ResRetImg)`
  min-height: 100%;
`;

const StyledFeaturedPostContent = styled.div`
  padding: 30px;
  color: ${standardColours.white};

  ${minBreakpointQuery.small`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  `}

  ${minBreakpointQuery.medium`
    padding: 40px;
  `}

  ${minBreakpointQuery.large`
    padding: 50px;
  `}
`;

const StyledFeaturedPostType = styled.h2`
  padding-bottom: 30px;
  ${fontSize(16)};

  ${minBreakpointQuery.tiny`
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.small`
    margin-bottom: auto;
  `}
`;

const StyledFeaturedPostDate = styled.p`
  margin-bottom: 10px;

  ${minBreakpointQuery.tiny`
    margin-bottom: 12px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 14px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 16px;
  `}
`;

const StyledFeaturedPostHeading = styled.h3`
  ${fontSize(20)};

  ${minBreakpointQuery.tiny`
    ${fontSize(22)};
  `}
`;

const StyledFeaturedPostButton = styled(Link)`
  display: block;
  margin-top: 18px;
  padding: 12px 16px;
  font-weight: ${fontWeights.bold};
  text-align: center;
  border: 1px solid ${standardColours.white};

  ${minBreakpointQuery.medium`
    margin-top: 22px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 26px;
  `}
`;

const FeaturedPost = ({
  data: { title, slug, featuredImage, dateText, dateAttribute },
  postType,
  baseSlug,
}) => {
  return (
    <StyledFeaturedPost>
      <Container>
        <StyledFeaturedPostInner onClick={() => navigate(baseSlug + slug)}>
          <StyledFeaturedPostBlock>
            <StyledFeaturedPostImage image={featuredImage} />
          </StyledFeaturedPostBlock>
          <StyledFeaturedPostBlock>
            <StyledFeaturedPostContent>
              <StyledFeaturedPostType>
                Featured {postType}
              </StyledFeaturedPostType>
              <StyledFeaturedPostDate>
                <time dateTime={dateAttribute}>{dateText}</time>
              </StyledFeaturedPostDate>
              <StyledFeaturedPostHeading>{title}</StyledFeaturedPostHeading>
              <StyledFeaturedPostButton to={baseSlug + slug}>
                View {postType}
              </StyledFeaturedPostButton>
            </StyledFeaturedPostContent>
          </StyledFeaturedPostBlock>
        </StyledFeaturedPostInner>
      </Container>
    </StyledFeaturedPost>
  );
};

export default FeaturedPost;
